<template>
  <VerticalBarsChartStyled>
    <canvas width="100%" height="100%" ref="canvas" />
  </VerticalBarsChartStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import ChartMixin from '@/mixins/ChartMixin'
const VerticalBarsChartStyled = styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
}
`
export default {
  mixins: [ChartMixin],
  components: {
    VerticalBarsChartStyled,
  },
  data() {
    return {
      chartType: 'bar',
    }
  },
}
</script>
