import Chart from 'chart.js'
import { DateTime } from 'luxon'

export default {
  inject: ['theme'],
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    scales: {
      type: Object,
      required: true,
    },
    tooltips: {
      type: Object,
    },
    labelUnit: {
      type: String,
    },
    labelsAreDateTimes: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    centerText: {
      type: String,
    },
    withCenterText: {
      type: Boolean,
      default: false,
    },
    centerTextSub: {
      type: String,
    },
    displayLegend: {
      type: Boolean,
      default: false,
    },
    displayTitle: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
    },
  },
  data() {
    return {
      chart: null,
      chartType: 'bar',
    }
  },
  watch: {
    datasets: {
      handler() {
        if (!this.chart) {
          console.error('missing chart instance') // eslint-disable-line no-console
          return
        }
        this.chart.data.datasets = this.datasets
        if (this.displayLegend) {
          // fix for the theme color change to reflect
          this.chart.options.legend.labels.fontColor = this.fontColor
        }

        if (this.displayTitle) {
          // fix for the translation of Legend title
          this.chart.options.title.display = this.displayTitle
          this.chart.options.title.text = this.title
          this.chart.options.title.fontColor = this.fontColor
        }
        this.chart.update()
      },
    },
    labels: {
      handler() {
        if (!this.chart) {
          console.error('missing chart instance') // eslint-disable-line no-console
          return
        }
        this.chart.data.labels = this.labels
        this.chart.update()
      },
    },
    scales: {
      handler() {
        if (!this.chart) {
          return
        }
        this.chart.options.scales = this.scales
        this.chart.update()
      },
    },
    centerText: {
      handler() {
        if (!this.chart || !this.withCenterText) {
          return
        }
        this.chart.options.elements.center.text = this.centerText
        this.chart.options.elements.center.text2 = this.centerTextSub
        this.chart.update()
      },
    },
    displayLegend: {
      handler() {
        this.chart.options.legend.display = this.displayLegend
        this.chart.options.legend.labels.fontColor = this.fontColor
        this.chart.update()
      },
    },
    displayTitle: {
      handler() {
        this.chart.options.title.display = this.displayTitle
        this.chart.options.title.text = this.title
        this.chart.options.title.fontColor = this.fontColor
        this.chart.update()
      },
    },
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {
      const ctx = this.$refs.canvas
      if (!ctx) {
        throw new Error('Missing chart ctx')
      }
      const options = {
        maintainAspectRatio: false,
        scales: this.scales,
        legend: {
          display: this.displayLegend,
          labels: {
            fontColor: this.fontColor,
            fontSize: 14,
          },
        },
        title: {
          display: this.displayTitle,
          text: this.title,
          fontSize: 14,
          fontColor: this.fontColor,
        },
      }
      if (this.centerText) {
        options.elements = {
          center: {
            text: this.centerText,
            text2: this.centerTextSub,
            sidePadding: 30,
          },
        }
      }
      if (this.tooltips) {
        options.tooltips = this.tooltips
      } else if (this.labelUnit || this.labelsAreDateTimes) {
        options.tooltips = {
          callbacks: {},
        }
        if (this.labelUnit) {
          options.tooltips.callbacks.label = (tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || ''
            const value = this.chartType !== 'horizontalBar' ? tooltipItem.yLabel : tooltipItem.xLabel
            if (label) {
              label += ': '
            }
            label += Math.round(value * 100) / 100
            label += ' ' + this.labelUnit
            return label
          }
        }
        if (this.labelsAreDateTimes) {
          options.tooltips.callbacks.title = item => {
            const value = this.chartType === 'horizontalBar' ? item[0].yLabel : item[0].xLabel
            return value.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
          }
        }
      }
      this.chart = new Chart(ctx, {
        type: this.chartType,
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options,
      })
    },
  },
}
