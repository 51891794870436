var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('VerticalBarsChartStyled', [_c('canvas', {
    ref: "canvas",
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }